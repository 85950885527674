export const backendserverurl='https://11-be-n3xt-kc4s.pitech.co.ug'

//127.0.0.1:8000
//157.245.89.89
//https://1-be-pifs01-mta-app1.pitech.co.ug

//https://2-be-pifs02-mta-app2.pitech.co.ug


//https://7-be-n3xt-d0va.pitech.co.ug
//https://8-be-n3xt-m4sa.pitech.co.ug

//	11-be-n3xt-kc4s.pitech.co.ug

